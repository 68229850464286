import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

const CheckboxContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  margin-right: 16px;
  margin-bottom: 8px;
  font-size: 20px;
  color: #333;
  cursor: pointer;

  &:hover {
    color: #0066cc;
  }
`;

const CheckboxInput = styled.input`
  margin-right: 8px;
  cursor: pointer;
`;

const DropdownContainer = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  z-index: 1;
  overflow-y: auto;
  max-height: 200px;
`;

const DropdownOption = styled.label`
  display: flex;
  align-items: center;
  padding: 8px;
  font-size: 16px;
  color: #333;
  cursor: pointer;
  margin-left: 20px;

  &:hover {
    color: #0066cc;
    background-color: #f0f0f0;
  }
`;

const SelectedValuesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 8px;
`;

const SelectedValue = styled.div`
  margin-right: 8px;
  margin-bottom: 8px;
  padding: 4px 8px;
  border-radius: 20px;
  background-color: #f0f0f0;
  font-size: 16px;
  color: #333;
`;

const SelectedValues = ({ selectedValues }) => {
  return (
    <SelectedValuesContainer>
      {selectedValues.map(value => (
        <SelectedValue key={value}>{value}</SelectedValue>
      ))}
    </SelectedValuesContainer>
  );
};

function CheckboxList({ values, onChange, title }) {
  const [checkedItems, setCheckedItems] = useState({});
  const [isExpanded, setIsExpanded] = useState(false);
  const dropdownRef = useRef(null);

  const handleChange = (event) => {
    const { name, checked } = event.target;
    const newCheckedItems = { ...checkedItems, [name]: checked };
    setCheckedItems(newCheckedItems);
    const checkedValues = Object.keys(newCheckedItems).filter((key) => newCheckedItems[key]);
    onChange(checkedValues);
  };

  const handleDropdownToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsExpanded(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const selectedValues = Object.keys(checkedItems).filter((key) => checkedItems[key]);

  return (
    <CheckboxContainer ref={dropdownRef}>
      <CheckboxLabel onClick={handleDropdownToggle}>
      <h1 style={{ marginBottom: '1px' }}>{title} +</h1>
      </CheckboxLabel>
      <div> {/* new div element to show selected values */}
        {selectedValues.length > 0 && (
          <SelectedValues selectedValues={selectedValues} />
        )}
      </div>
      {isExpanded && (
        <DropdownContainer>
          {values.map((value) => (
            <DropdownOption key={value}>
              <CheckboxInput
                type="checkbox"
                name={value}
                checked={checkedItems[value]}
                onChange={handleChange}
              />
              {value}
            </DropdownOption>
          ))}
        </DropdownContainer>
      )}
    </CheckboxContainer>
  );
}



export default CheckboxList;
