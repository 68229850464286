import React from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  CardActions,
} from "@material-ui/core/";

export const Products = ({ products }) => {
  console.log("products length:::", products.length);
  if (products.length === 0) return null;

  const imageStyle = {
    maxWidth: "100%",
    maxHeight: "100%",
  };
  const a = {
    color: "white",
    backgroundColor: '#2b4ba4',
    justifyContent: "center",
  };

  const ProductRow = (user) => {
    return (
      <>
        <Grid item xs>
          <Card
            style={{
              width: 230,
              backgroundColor: "white",
            }}
          >
            <CardContent>
              <img src={user.imageUrl} style={imageStyle} />
              <br></br>
              <Typography variant="body1" component="p">
                ${user.price}
              </Typography>
              <Typography variant="body1" component="p">
                Brand : <b>{user.brand}</b>
              </Typography>
              <Typography variant="body1" component="p">
                Size : {user.size}
              </Typography>
              <Typography variant="body1" component="p">
                Color : {user.color}
              </Typography>
            </CardContent>
            <CardActions style={a}>
              <b>
                <a target="_blank" href={user.productUrl} style={a}>
                  {" "}
                  GRAB-ME{" "}
                </a>
              </b>
            </CardActions>
          </Card>
          <br></br>
        </Grid>
      </>
    );
  };

  const userTable = products.map((user) => ProductRow(user));
  return (
    <div>
      <Grid
        container
        spacing={1}
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
      >
        {userTable}
      </Grid>
    </div>
  );
};
