import React, { useState } from "react";
import CheckboxList from "./CheckboxList";
import { Products } from "./Card";
import "./Search.css";
import searchProducts from "../../services/searchService";

function Search() {
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedSizes, setSelectedSizes] = useState([]);
  const [selectedGenders, setSelectedGenders] = useState([]);
  const [selectedColors, setSelectedColors] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const brands = [
    "Abercrombie & Fitch",
    "Armani Exchange",
    "Athleta",
    "Banana Republic",
    "Calvin Klein",
    "Columbia",
    "DKNY",
    "Gap",
    "Guess",
    "Hollister",
    "Hugo Boss",
    "Lacoste",
    "Levi's",
    "Old Navy",
    "Polo Ralph Lauren",
    "Tommy Hilfiger",
    "Others",
  ];
  const size = ["XXS", "XS", "S", "M", "L", "XL", "XXL"];
  const gender = ["MENS", "WOMENS"];
  const colors = [
    "Red",
    "Orange",
    "Yellow",
    "Green",
    "Gold",
    "Grey",
    "Pink",
    "Gray",
    "Beige",
    "Blue",
    "Purple",
    "White",
    "Brown",
    "Black",
    "Multi",
  ];
  const categories = [
    "T-shirts",
    "Tops",
    "Shirts",
    "Jeans",
    "Polos",
    "Accessiories",
    "Blazers",
    "Hoodies",
    "Jackets & Coats",
    "Joggers",
    "Pants",
    "Shorts",
    "Sweaters",
    "Sweatshirt",
    "Swimwear",
    "Others",
  ];

  const handleCheckboxChange = (checkedValues, setType) => {
    setType(checkedValues);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const data = {
      brands: selectedBrands,
      sizes: selectedSizes,
      genders: selectedGenders,
      colors: selectedColors,
      categories: selectedCategories,
    };

    try {
      const products = await searchProducts(data);
      setProducts(products);
      console.info("Successful!");
    } catch (error) {
      console.error("Failed to search products:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="container">
        <div className="filter">
          <h1>FILTERS</h1>
          <CheckboxList
            values={brands}
            title="BRAND"
            onChange={(checkedValues) =>
              handleCheckboxChange(checkedValues, setSelectedBrands)
            }
          />
          <CheckboxList
            values={size}
            title="SIZE"
            onChange={(checkedValues) =>
              handleCheckboxChange(checkedValues, setSelectedSizes)
            }
          />
          <CheckboxList
            values={gender}
            title="GENDER"
            onChange={(checkedValues) =>
              handleCheckboxChange(checkedValues, setSelectedGenders)
            }
          />
          <CheckboxList
            values={colors}
            title="COLOR"
            onChange={(checkedValues) =>
              handleCheckboxChange(checkedValues, setSelectedColors)
            }
          />
          <CheckboxList
            values={categories}
            title="CATEGORY"
            onChange={(checkedValues) =>
              handleCheckboxChange(checkedValues, setSelectedCategories)
            }
          />
          <div className="button-container" >
            <button
              style={{
                color: "white",
                backgroundColor: "black",
                padding: "10px 20px",
                height: "40px",
                width: "150px",
                marginLeft: "10px",
              }}
              onClick={handleSubmit}
            >
              {isLoading ? <div className="loader"></div> : "Apply"}
            </button>
          </div>
        </div>
        <div className="line-separator"></div>
        <div className="prod">
          <Products products={products}></Products>
        </div>
      </div>
    </>
  );
}

export default Search;
