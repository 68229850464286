import React from "react";
import "../App.css";
import { Button } from "./Button";
import "./HeroSection.css";

function HeroSection() {
  return (
    <div className="hero-container">
      <video src="/videos/video-1.mp4" autoPlay loop muted playsInline>
        <source src="/videos/video-1.mp4" type="video/mp4" />
        <source src="/videos/video-1.webm" type="video/webm" />
        <source src="/videos/video-1.ogv" type="video/ogg" />
        Your browser does not support the video tag.
      </video>
      <h1>BEST DEALS</h1>
      <p>What are you waiting for?</p>
      <div className="hero-btns">
        <Button
          className="btns"
          buttonStyle="btn--outline"
          buttonSize="btn--large"
          to={"/services"}
        >
          GET STARTED
        </Button>
      </div>
    </div>
  );
}

export default HeroSection;
